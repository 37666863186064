<template>
  <div class="d-breadcrumb">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-for="(item, index) in $route.meta"
        :key="'a-' + index"
        :class="index == $route.meta.length - 1 ? 'd-actived-bread' : ''"
      >
        {{item}}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>

export default {
  name: 'd-breadcrumb',
  props: {
    data: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      breadData: []
    }
  },
  watch: {
    data: {
     
    }
  },
  methods: {
    },
  created () {
  }
}
</script>

<style lang="stylus" >
.d-breadcrumb
  width 100%
  margin 0 auto 16px
  font-family 'Manrope'
  font-style normal
  font-weight 400
  font-size 14px
  color #000
  opacity 0.5
  .d-actived-bread {
    color red
  }
</style>

<template>
    <div class="container">
        <el-container style="display: -webkit-box">
            <el-header class="el-header" style="height: 40px">
                <div
                    style="
            width: 198px;
            font-size: 35px;
            text-align: center;
            color: rgb(255, 113, 0);
            border-right: 2px solid rgb(225, 225, 225);
          "
                >
                    猿人创新
                </div>
                <!-- <img class="logo" src="../assets//image//logo.png" alt="">  -->
                <ul class="submenu">
                    <li
                        class="submenu-li"
                        v-for="(item, index) in menus"
                        @click="topMenuClick(item)"
                        :key="index"
                        :class="[{ 'is-active': topname == item.name }]"
                    >
                        <router-link
                            style="display: block; height: 100%"
                            :to="item.route"
                        >
                            {{ item.name }}
                        </router-link>
                    </li>
                </ul>
                <div class="logout">
                    <el-select
                        v-model="regionInfo"
                        @change="regionClick"
                        placeholder="切换区域"
                    >
                        <el-option
                            v-for="item in regionList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                    &nbsp; | &nbsp;
                    <span
                        @click="updateAdminer"
                        style="color: rgb(255, 0, 0); cursor: pointer"
                        >{{ user }}</span
                    >
                    &nbsp; | &nbsp;
                    <span @click="logout" style="color: white; cursor: pointer"
                        >注销</span
                    >
                </div>
            </el-header>
            <el-container>
                <el-aside
                    width="200px"
                    :class="isOpen ? 'openMenu' : 'closeMenu'"
                >
                    <el-menu
                        :default-active="activeAsideIndex + ''"
                        :default-openeds="[activeAsideIndex + '']"
                        @click="asideMenuClick(item, index)"
                        v-for="(item, index) in asidemenu"
                        :key="index + ''"
                        text-color="#fff"
                        active-text-color="#ffd04b"
                    >
                        <component
                            class="menu-item"
                            :class="[
                                { 'is-active': item.route == activeAsideIndex }
                            ]"
                            v-if="item.children && item.children.length > 0"
                            :is="'el-submenu'"
                            :index="item.route"
                        >
                            <template #title>
                                <i class="el-icon-menu"></i>
                                <span>{{ item.name }}</span>
                            </template>
                            <el-menu-item
                                @click="ThirdMenu(v, i)"
                                v-for="(v, i) in item.children"
                                :key="v.route + i"
                                :index="v.route"
                                :class="[
                                    {
                                        'is-active':
                                            v.route == thirdMenuCurrent + ''
                                    }
                                ]"
                            >
                                <router-link
                                    style="display: block; width: 100%; height: 100%"
                                    :to="v.route"
                                >
                                    <i class="el-icon-location"></i>
                                    {{ v.name }}
                                </router-link>
                            </el-menu-item>
                        </component>
                        <el-menu-item
                            :class="[
                                {
                                    'is-active':
                                        item.route == activeAsideIndex + ''
                                }
                            ]"
                            v-else
                            :index="item.route"
                        >
                            <router-link
                                style="display: block; width: 100%; height: 100%"
                                :to="item.route"
                            >
                                <i class="el-icon-menu"></i>
                                {{ item.name }}
                            </router-link>
                        </el-menu-item>
                    </el-menu>
                </el-aside>
                <i
                    v-if="isOpen"
                    @click="closeMenu"
                    class="el-icon-arrow-left draw-arrow-left"
                ></i>
                <i
                    v-else
                    @click="openMenu"
                    class="el-icon-arrow-right draw-arrow-right"
                ></i>
                <el-main>
                    <el-card
                        @click.middle="mouseCenterclick"
                        @click.right="mouseclick"
                        @click.left="mouseclick"
                        shadow="never"
                    >
                        <d-breade ref="bread"></d-breade>
                        <router-view></router-view>
                    </el-card>
                </el-main>
                <!-- <el-footer>Footer</el-footer> -->
            </el-container>
        </el-container>

        <el-dialog
            title="修改个人信息"
            v-model="dialogFormVisible"
            width="600px"
        >
            <el-form :model="adminer" :rules="rules" ref="form">
                <el-form-item label="账号" label-width="100px">
                    <el-input
                        v-model="adminer.account"
                        autocomplete="off"
                        disabled
                    ></el-input>
                </el-form-item>
                <el-form-item label="名称" label-width="100px">
                    <el-input
                        v-model="adminer.name"
                        autocomplete="off"
                        disabled
                    ></el-input>
                </el-form-item>
                <el-form-item label="电话" label-width="100px">
                    <el-input
                        v-model="adminer.phone"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="原密码"
                    prop="old_password"
                    label-width="100px"
                >
                    <el-input
                        :type="oldtype"
                        v-model="adminer.old_password"
                        autocomplete="off"
                        placeholder="原密码"
                    >
                        <template #append
                            ><el-button
                                @click="LookPass('old')"
                                icon="el-icon-view"
                            ></el-button
                        ></template>
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="新密码"
                    prop="password"
                    label-width="100px"
                >
                    <el-input
                        :type="newtype"
                        v-model="adminer.password"
                        autocomplete="off"
                        placeholder="新密码"
                    >
                        <template #append
                            ><el-button
                                @click="LookPass('new')"
                                icon="el-icon-view"
                            ></el-button
                        ></template>
                    </el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogFormVisible = false"
                        >取 消</el-button
                    >
                    <el-button type="primary" @click="Validate(submitAdminer)"
                        >确 定</el-button
                    >
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
import dBreade from "../components/d-bread.vue"
import md5 from "js-md5"
export default {
    name: "",
    mixins: [],
    components: {
        dBreade,
    },
    props: {},
    data() {
        var checkPassword = (rule, value, callback) => {
            if (value == "") {
                callback()
            }
            let reg =
                /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{6,24}$/
            if (!reg.test(value)) {
                callback(
                    new Error(
                        "密码须由数字、小写字母、大写字母、特殊字符至少三种构成,长度至少为6位!"
                    )
                )
            } else {
                callback()
            }
        }
        return {
            isOpen: true,
            menus: [],
            activeIndex: 1,
            asidemenu: [],
            dialogFormVisible: false,
            thirdMenuCurrent: "",
            adminer: {
                id: 0,
                name: "",
                phone: "",
                account: "",
                old_password: "",
                password: "",
            },
            rules: {
                // old_password: [{ required: false, validator: checkPassword, trigger: 'blur' }],
                password: [
                    { required: false, validator: checkPassword, trigger: "blur" },
                ],
            },
            activeAsideIndex: 0,
            user: "",
            regionList: [],
            regionInfo: "",
            topname: "",
            activeasideName: "",
            oldtype: "password",
            newtype: "password",
        }
    },
    computed: {},
    watch: {},
    methods: {
        openMenu() {
            this.isOpen = true
        },
        closeMenu() {
            this.isOpen = false
        },
        mouseCenterclick() {
            localStorage.setItem("middle", true)
        },
        mouseclick() {
            localStorage.setItem("middle", false)
        },
        LookPass(item) {
            if (item == "old") {
                this.oldtype = this.oldtype == "password" ? "text" : "password"
            } else {
                this.newtype = this.newtype == "password" ? "text" : "password"
            }
        },
        regionChange() {
            localStorage.setItem("region_change", this.regionInfo)
            this.GetAllMenu(true)
        },
        GetAllMenu(regionChange) {
            this.api.AdminMenu({}).then((res) => {
                if (res.data.code == 200) {
                    localStorage.setItem("allmenu", JSON.stringify(res.data.data))
                    if (regionChange) {
                        for (let i = 0; i <= res.data.data.length; i++) {
                            if (
                                i < res.data.data.length &&
                                localStorage.getItem("submenu_name") == res.data.data[i].name
                            ) {
                                localStorage.setItem("this_submenu", i)
                                this.changeasidemenu("", true, "", res.data.data[i])
                                window.location.reload()
                                return
                            } else if (
                                i >= res.data.data.length &&
                                localStorage.getItem("submenu_name") !==
                                res.data.data[i - 1].name
                            ) {
                                localStorage.setItem("this_submenu", 0)
                            }
                        }
                        localStorage.setItem("this_asidemenu", 0)
                        this.changesubmenu(
                            res.data.data[
                            localStorage.this_submenu ? localStorage.this_submenu : 0
                            ],
                            localStorage.this_submenu ? localStorage.this_submenu : 0,
                            "",
                            true
                        )
                        this.changeasidemenu(0, true)
                        window.location.reload()
                    } else {
                        console.info("这里打印一下", this.asidemenu[this.activeAsideIndex].route)

                        this.$router.push(this.asidemenu[this.activeAsideIndex].route)
                        this.$router.push({ path: "/index" }).catch((err) => {
                            console.log(err)
                        })
                    }
                    return
                }
                this.$message.error("获取菜单失败" + res.data.msg)
            })
        },
        // 一个修改
        changesubmenu(item, index, which, region) {
            this.topname = item.name
            sessionStorage.clear()
            if (which == "sub") {
                localStorage.setItem("this_asidemenu", 0)
            }
            this.activeAsideIndex = 0
            this.activeIndex = index
            this.asidemenu = item.children
            // 保存现在点击的菜单
            localStorage.setItem("this_submenu", index)
            if (which !== "mounted") {
                this.$router.push(
                    this.asidemenu[localStorage.getItem("this_asidemenu")].route
                )
            }
            localStorage.setItem("submenu_name", item.name)
        },
        updateAdminer() {
            // 先获取用户信息
            this.dialogFormVisible = !this.dialogFormVisible
            this.api.AdminerSelfInfo({}).then((res) => {
                if (res.data.code == 200) {
                    this.adminer = res.data.data
                    this.adminer.password = ""
                    return
                }
                this.$message.error("获取详情失败" + res.data.msg)
            })
        },
        Validate: function (call) {
            this.$refs["form"].validate((res) => {
                if (res && call) {
                    call()
                }
            })
        },
        submitAdminer() {
            this.api
                .AdminerSelfUpdate({
                    old_password: md5(this.adminer.old_password),
                    password: md5(this.adminer.password),
                    phone: this.adminer.phone,
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.$message.success("修改成功")
                        localStorage.removeItem("password_status")
                        this.dialogFormVisible = false
                        return
                    }
                    this.$message.error("修改失败" + res.data.msg)
                })
        },
        logout() {
            // let timestamp = new Date().getTime()
            // let header = {
            //   request_id: timestamp,
            //   client_time: parseInt(timestamp/1000),
            //   'CA-KEYS': "client_time,request_id",
            // }
            // header.signature = this.signature(timestamp,param,header,'get')
            // let _this = this
            this.$confirm("确定注销登录?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {

                    //   this.$axios.get('/admin/v1/login/out',{
                    //   params: '',
                    //   headers: header
                    // }).then(res => {
                    //     if (res.data.code === 200) {
                    //       _this.$message({
                    //         type: 'success',
                    //         message: '注销成功!'
                    //       });
                    //       return
                    //     }
                    //     this.$message.error('注销失败！')
                    //   })
                    this.$router.push("/login")
                    localStorage.clear()
                })
                .catch(() => {
                    _this.$message({
                        type: "info",
                        message: "已取消注销",
                    })
                })
        },
        /**
         * @param {string} index 二级菜单的数组索引
         * @param {string} which 二级菜单的点击类型
         * @param {string} name 二级菜单项
         */
        changeasidemenu(index, changeRegion, item, asidemenu) {
            if (item) {
                localStorage.setItem("aside_menuname", item.name)
            }
            if (changeRegion && asidemenu) {
                for (let i = 0; i <= asidemenu.children.length; i++) {
                    if (
                        i < asidemenu.children.length &&
                        localStorage.getItem("aside_menuname") == asidemenu.children[i].name
                    ) {
                        localStorage.setItem("this_asidemenu", i)
                        window.location.reload()
                        return
                    } else if (
                        i >= asidemenu.children.length &&
                        localStorage.getItem("submenu_name") !==
                        asidemenu.children[i - 1].name
                    ) {
                        localStorage.setItem("this_asidemenu", 0)
                    }
                }
            } else if (changeRegion && !asidemenu) {
                for (let i = 0; i <= this.asidemenu.length; i++) {
                    if (
                        i < this.asidemenu.length &&
                        localStorage.getItem("aside_menuname") == this.asidemenu[i].name
                    ) {
                        localStorage.setItem("this_asidemenu", i)
                        window.location.reload()
                        return
                    } else if (
                        i >= this.asidemenu.length &&
                        localStorage.getItem("submenu_name") !== this.asidemenu[i - 1].name
                    ) {
                        localStorage.setItem("this_asidemenu", 0)
                    }
                }
            }
            sessionStorage.clear()
            this.activeAsideIndex = index
            localStorage.setItem("this_asidemenu", this.activeAsideIndex)
            if (
                this.asidemenu[index] &&
                (this.$router.currentRoute._value.fullPath == "/index" || changeRegion)
            ) {
                this.$router.push(this.asidemenu[index].route)
                localStorage.setItem("aside_menuname", this.asidemenu[index].name)
            } else if (!this.asidemenu[index] && asidemenu) {
                this.$router.push(asidemenu.children[0].route)
                localStorage.setItem("aside_menuname", asidemenu.children[0].name)
                localStorage.setItem("this_asidemenu", 0)
            } else if (this.asidemenu[index] && typeof changeRegion === "undefined") {
                console.log(
                    this.$router.currentRoute._value.path,
                    "路径",
                    this.asidemenu[index].route,
                    "菜单"
                )
                this.$router.push(this.asidemenu[index].route)
            }
        },
        ThirdMenu(item) {
            let thirdMenuCurrent = item.route
            this.thirdMenuCurrent = item.route
            localStorage.setItem("thirdMenuCurrent", thirdMenuCurrent)
            sessionStorage.clear()
        },
        /**
         * 以下是修改菜单缓存的逻辑
         */
        // 从线上拉取菜单
        async getMenu() {
            // 从线上拉取菜单只做渲染不做缓存
            this.api.AdminMenu({}).then((res) => {
                if (res.data.code == 200) {
                    let result = res.data.data
                    this.menus = JSON.parse(JSON.stringify(result))
                    let topMenuCurrent = localStorage.getItem("topMenuCurrent") != null || localStorage.getItem("topMenuCurrent") != 'null' ? JSON.parse(localStorage.getItem("topMenuCurrent")) : null
                    let asideMenuCurrent = localStorage.getItem("secondMenuCurrent")

                    // 选中顶级菜单
                    if (topMenuCurrent != null && topMenuCurrent != 'null') {
                        this.topname = topMenuCurrent.name
                        this.asidemenu = topMenuCurrent.children
                    } else {
                        this.topname = result[0].name
                        this.asidemenu = result[0].children
                    }
                    // 选中二级菜单
                    if (asideMenuCurrent != null && asideMenuCurrent != 'null') {
                        this.activeAsideIndex = asideMenuCurrent
                    } else {
                        this.activeAsideIndex = result[0].children[0].route
                        this.$router.push(result[0].children[0].route)
                    }
                    // 选中三级菜单
                    let thirdMenuCurrent = localStorage.getItem("thirdMenuCurrent")

                    if (thirdMenuCurrent != null && thirdMenuCurrent != 'null') {
                        this.thirdMenuCurrent = thirdMenuCurrent + ''
                    } else {
                        // this.activeAsideIndex = result[0].children[0].children[0].route;
                    }
                    return
                }
                this.$message.error("获取菜单失败" + res.data.msg)
            })
        },
        asideMenuClick(item) {
            this.activeAsideIndex = item.route
            let secondMenuCurrent = item.route
            localStorage.setItem("secondMenuCurrent", secondMenuCurrent)
        },
        topMenuClick(item) {
            this.activeAsideIndex = item.children[0].route
            this.topname = item.name
            this.asidemenu = item.children
            let topMenuCurrent = item
            localStorage.setItem("topMenuCurrent", JSON.stringify(topMenuCurrent))
        },
        regionClick(val) {
            this.regionInfo = val
            // 切换区域清除所有菜单缓存
            let pure = null
            localStorage.setItem('thirdMenuCurrent', pure)
            localStorage.setItem('secondMenuCurrent', pure)
            localStorage.setItem('topMenuCurrent', pure)
            localStorage.setItem("region_change", val)
            this.getMenu()
        },
    },
    filters: {},
    mounted() {
        if (!localStorage.is_login) {
            this.$router.push("/login")
            return
        }
        this.regionList = JSON.parse(localStorage.getItem("user_info")).region_list

        // this.menus = JSON.parse(localStorage.getItem('allmenu'))

        this.regionInfo = localStorage.getItem("region_change")
        // // 刷新保持原路由
        this.user = JSON.parse(localStorage.getItem("user_info"))["name"]
        this.getMenu()
        // this.changesubmenu(this.menus[localStorage.this_submenu?localStorage.this_submenu:0],localStorage.this_submenu?localStorage.this_submenu:0,'mounted')
        // //
        // if (!JSON.parse(localStorage.getItem('middle'))) {
        //   this.changeasidemenu(localStorage.this_asidemenu?localStorage.this_asidemenu:0)
        // }
        if (localStorage.getItem("password_status") == 2) {
            this.$message.warning(
                "您的密码已过期，请及时修改密码，否则下次将无法正常登录！"
            )
            this.updateAdminer()
        }
    },
    created() { },
}
</script>
<style>
.openMenu {
    display: block;
}

.closeMenu {
    display: none;
}

.draw-arrow-left {
    font-size: 25px;
    color: #fff;
    position: absolute;
    left: 180px;
    top: 50%;
    margin-top: 12px;
}

.draw-arrow-right {
    font-size: 25px;
    position: absolute;
    z-index: 9999;
    left: 0px;
    top: 50%;
    margin-top: 12px;
}

.el-menu,
.el-menu-vertical-demo {
    background-color: rgb(33, 41, 57);
}

/* .el-menu{
  border-right: none
} */
.main-menu {
    height: 800px;
    overflow-y: scroll;
}

.main-collapse {
}
</style>
<style scoped>
.logout .el-select {
    width: 150px;
    height: 20px;
}

.container .el-container {
    height: 100%;
}

.el-header {
    padding: 0;
    display: flex;
    background-color: rgb(33, 41, 57);
    line-height: 40px;
}

.logo {
    border-bottom: 2px solid rgb(220, 220, 220);
}

.submenu {
    font-size: 14px;
    display: flex;
    color: white;
    flex: 1;
    /* background-color: rgb(225, 225, 225); */
}

.submenu-li {
    /* height: 40px; */
    padding: 0 20px;
}

.is-active {
    background-color: rgb(189, 100, 0);
    border-bottom: none;
}

.is-active .router-link-active {
    color: #fff;
}

.el-aside {
    background-color: rgb(33, 41, 57);
    border-right: 2px solid rgb(220, 220, 220);
}

.logout {
    /* background-color: rgb(225, 225, 225); */

    padding-right: 90px;
    font-size: 14px;
}

/* .asidemenu-li { */
/* border-top: 1px solid rgb(235, 235, 235);
  border-bottom: 1px solid rgb(235, 235, 235); */
/* } */

.asidemenu-li a {
    height: 40px;
    display: block;
    text-overflow: ellipsis;
    line-height: 40px;
    font-size: 14px;
    width: 100%;
    text-align: center;
}

.el-menu-item a:hover {
    color: rgb(255, 125, 37) !important;
}

.el-submenu__title span {
    display: inline-block;
    width: 100%;
}

.el-submenu__title span:hover {
    color: rgb(255, 125, 37) !important;
}

.asideli-active {
    background-color: rgb(189, 100, 0);
}

.el-card {
    margin-top: 10px;
}

::v-deep(.el-card__body) {
    padding: 16px 24px 24px 24px;
}

/* 修改分页样式 */
::v-deep(.el-pagination.is-background) {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

::v-deep(.el-pagination.is-background .btn-next:not(:disabled)) {
    background-color: #fff;
    color: #000;
    border: 1px solid #f0f2f5;
    border-radius: 4px;
}

::v-deep(.el-pagination.is-background .btn-prev:not(:disabled)) {
    background-color: #fff;
    color: #000;
    border: 1px solid #f0f2f5;
    border-radius: 4px;
}

::v-deep(.el-pagination.is-background .el-pager li) {
    background-color: #fff;
    color: #000;
    border: 1px solid #f0f2f5;
    border-radius: 4px;
}

::v-deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
    background-color: #fb6d07;
    color: #fff;
}

::v-deep(.el-pagination.is-background .el-pager li.active) {
    color: #fff;
    cursor: default;
}

::v-deep(.el-pagination.is-background .el-pager li:hover) {
    color: #fb6d07;
}

::v-deep(.el-pagination.is-background .el-pager li:not(.disabled):hover) {
    color: #fb6d07;
}

::v-deep(.el-pagination.is-background
        .el-pager
        li:not(.disabled).active:hover) {
    background-color: #fb6d07;
    color: #fff;
}

::v-deep(.el-pagination__total) {
    border: 1px solid #f0f2f5;
    border-radius: 4px;
    padding: 0px 16px;
}

.el-footer {
    background-color: #fff;
    border-top: 2px solid rgb(220, 220, 220);
}
</style>
